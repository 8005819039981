import React, { FC } from "react";
import Image from "next/image";

const Contact: FC = () => {
    return (
        <>
            <div className="flex h-full w-full flex-col items-center justify-center gap-10 lg:my-40 lg:justify-start">
                <div className="flex w-full flex-col items-center justify-center text-center font-['Inter']">
                    <h1 className="mt-10 text-5xl font-bold text-blue-200 ">
                        Get In Touch
                    </h1>
                    <p className="mt-10 w-[300px] text-blue-100 text-opacity-70 sm:w-[600px]">
                        I offer 1:1 mentorship services on Topmate. I can help
                        you with tech and tech careers.
                    </p>
                    <div className="h-full w-full border-b-[0.8px] border-b-gray-100 border-opacity-20 py-4">
                        <a
                            href="https://www.topmate.io/sreekeshiyer"
                            aria-label="My Topmate Profile"
                        >
                            <img
                                src="/assets/featured-topmate.jpg"
                                className="rounded-md"
                                alt="Topmate Featured Image"
                            />
                        </a>
                    </div>

                    <p className="mt-10 w-[300px] text-blue-100 text-opacity-70 sm:w-[600px]">
                        If you want to approach me for a <b>part-time</b>{" "}
                        freelance opportunity, feel free to contact via email.
                        You can expect a reply within 2 days.
                    </p>
                </div>
                <div className="my-2">
                    <a
                        className="rounded-md border-2 border-[#6df9ff] p-4 text-[#6df9ff] transition-all duration-200 ease-in-out hover:bg-[#6df9ff] hover:bg-opacity-20"
                        target="_blank"
                        rel="noreferrer"
                        href="mailto:sreekeshiyer@gmail.com"
                    >
                        Say Hello!
                    </a>
                </div>
            </div>
            <div className="my-6"></div>
        </>
    );
};

export default Contact;
